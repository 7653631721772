<template>
  <v-col>
    <v-row class="mainRow">
      <v-card v-if="begin" class="calculatorCard">
        <img class="calculatorIcon" src="../../public/level-up-caret.png" />
        <p class="calculatorHeader">Eesti tööturu kalkulaator</p>
        <p class="calculatorDescription">
          Soovid palgata oma tiimi uut programmeerijat, turundajat või hoopis
          klienditeenindajat? Level up kalkulaatori abil on Sul võimalik saada
          täielik tööturu ülevaade. Kalkulaatori abil saad vastused: kui suur on
          Sinu otsitava ametipositsiooni sihtgrupp sotsiaalmeedias ja milliseid
          kanaleid valida, et soovitud talenti palgata.
        </p>
        <v-row class="searchRow" align="center" justify="center">
          <v-autocomplete
            v-model="ametiNimetus"
            :items="andmed"
            item-text="Ametipositsioon"
            outlined
            return-object
            v-on:change="calculate"
            append-icon="mdi-magnify"
            hide-no-data
          />
        </v-row>
        <v-row class align="center" justify="center">
          <v-select
            outlined
            class="selectors"
            v-model="valdKond"
            :items="andmed"
            label="Valdkond"
            item-text="Valdkond"
            return-object
          ></v-select>
        </v-row>
        <v-row align="center" justify="center">
          <v-select
            outlined
            class="selectors"
            v-model="ametiNimetus"
            :items="valitudAndmed"
            label="Ametinimetus"
            item-text="Ametipositsioon"
            return-object
            :disabled="!valdKond"
          ></v-select>
        </v-row>
        <v-btn id="calcBtn" class="calculatorBtn" @click="calculate()"
          >Kalkuleeri tulemus</v-btn
        >
      </v-card>
      <v-card v-if="begin" class="statsCard" id="calculations">
        <v-row class="statsRow">
          <img
            v-bind:class="{
              displayNone: loadingCounter === 0,
              displayLoading: loadingCounter === 1,
            }"
            src="../../public/loading.gif"
          />
          <img
            v-if="windowWidth < 1024"
            v-bind:class="{
              displayNone: loadingCounter === 0,
              displayLoadingMobile: loadingCounter === 1,
            }"
            src="../../public/loading.gif"
          />
          <v-card
            align="left"
            justify="left"
            v-bind:class="{
              profileCard: loadingCounter === 0,
              profileCardLoading: loadingCounter === 1,
            }"
          >
            <p class="p1">Ametipositsioon:</p>
            <p v-if="begin" class="p2">{{ begin.Ametipositsioon }}</p>
            <p class="p3">Sihtgrupi suurus Eestis</p>
            <p v-if="begin" class="p4">~{{ begin.Eesti }}</p>
            <v-row class="profileRow1">
              <img
                v-if="safari === false"
                class="profileIcon"
                src="../../public/profile-icon.png"
              />
              <v-progress-circular
                v-if="begin"
                width="7"
                class="progressCircle"
                size="60"
                color="#fa98cb"
                :value="begin.Naised"
                >{{ begin.Naised }}%</v-progress-circular
              >
              <v-progress-circular
                v-if="begin"
                width="7"
                class="progressCircle"
                size="60"
                color="#6353fa"
                :value="begin.Mehed"
                >{{ begin.Mehed }}%</v-progress-circular
              >
            </v-row>
            <v-row
              v-bind:class="{
                profileRow2: safari === false,
                profileRow2Safari: safari === true,
              }"
            >
              <p>Naised</p>
              <p
                v-bind:class="{
                  mehedParagraphSafari: safari === true,
                  mehedParagraph: safari === false,
                }"
              >
                Mehed
              </p>
            </v-row>
            <p class="p5">Sihtgrupi suurus Harjumaal</p>
            <p v-if="begin" class="p6">~{{ begin.Harjumaa }}</p>
            <p class="p7">Sihtgrupi suurus Tartumaal</p>
            <p v-if="begin" class="p8">~{{ begin.Tartumaa }}</p>
          </v-card>

          <v-card
            v-bind:class="{
              graphCards: loadingCounter === 0,
              graphCardsLoading: loadingCounter === 1,
            }"
          >
            <v-card class="graphCard">
              <p>Eesti töötuse määr</p>
              <div style="width: 90%; margin-left: 4%; height: 12vh">
                <canvas class="candidateChart" id="candidate-chart"></canvas>
              </div>
            </v-card>
            <v-card class="graphCard" style="margin-top: 6.8%">
              <p>
                Töötaja leidmise raskusaste
                <br />(tööturu konkurents)
              </p>

              <div style="width: 90%; margin-left: 4%; height: 12vh">
                <canvas class="candidateChart" id="candidate-chart2"></canvas>
              </div>
            </v-card>
          </v-card>
        </v-row>

        <v-row
          v-bind:class="{
            channelRow: loadingCounter === 0,
            channelRowLoading: loadingCounter === 1,
          }"
        >
          <v-col>
            <p class="channelParagraph">Kanalid, mida värbamiseks kasutada?</p>
            <span>
              <img
                v-bind:class="{
                  onLogo: lCounter === 0,
                  offLogo: lCounter === 1,
                }"
                src="../../public/off-linkedin-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: lCounter === 1,
                  offLogo: lCounter === 0,
                }"
                src="../../public/on-linkedin-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: fCounter === 0,
                  offLogo: fCounter === 1,
                }"
                src="../../public/off-facebook-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: fCounter === 1,
                  offLogo: fCounter === 0,
                }"
                src="../../public/on-facebook-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: mCounter === 0,
                  offLogo: mCounter === 1,
                }"
                src="../../public/off-messenger-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: mCounter === 1,
                  offLogo: mCounter === 0,
                }"
                src="../../public/on-messenger-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: iCounter === 0,
                  offLogo: iCounter === 1,
                }"
                src="../../public/off-instagram-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: iCounter === 1,
                  offLogo: iCounter === 0,
                }"
                src="../../public/on-instagram-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: yCounter === 0,
                  offLogo: yCounter === 1,
                }"
                src="../../public/off-youtube-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: yCounter === 1,
                  offLogo: yCounter === 0,
                }"
                src="../../public/on-youtube-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: gCounter === 0,
                  offLogo: gCounter === 1,
                }"
                src="../../public/off-google-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: gCounter === 1,
                  offLogo: gCounter === 0,
                }"
                src="../../public/on-google-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: vCounter === 0,
                  offLogo: vCounter === 1,
                }"
                src="../../public/off-vk-logo.png"
              />
              <img
                v-bind:class="{
                  onLogo: vCounter === 1,
                  offLogo: vCounter === 0,
                }"
                src="../../public/on-vk-logo.png"
              />
            </span>
          </v-col>

          <v-col style="padding-right: 3%">
            <p class="difficultyParagraph">
              Antud profiili palkamise raskusaste
            </p>
            <v-slider
              v-if="begin"
              class="difficultySlider"
              v-model="begin.Raskusaste"
              min="0"
              max="10"
              readonly
            ></v-slider>
            <p v-if="begin" class="sliderNumbers">
              {{ begin.Raskusaste }}/10st
            </p>
          </v-col>
        </v-row>
        <v-row
          v-bind:class="{
            serviceRow: loadingCounter === 0,
            serviceRowLoading: loadingCounter === 1,
          }"
        >
          <v-card class="serviceCard">
            <p class="serviceParagraph">
              Milline Level up teenus sobib palkamiseks kõige paremini?
            </p>
            <v-row class="serviceLine">
              <v-col class="serviceButton1">
                <div
                  v-bind:class="{
                    onService: varbCounter === 1,
                    offService: varbCounter === 0,
                  }"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://levelup.ee/varbamiskampaania-1/?utm_source=tooturu-kalkulaator&utm_medium=landing-page&utm_campaign=kalkulaator"
                    v-bind:class="{
                      serviceP: varbCounter === 1,
                      servicePoff: varbCounter === 0,
                    }"
                    >Värbamiskampaania</a
                  >
                </div>
              </v-col>
              <v-col class="serviceButton">
                <div
                  style="padding-top: 1rem"
                  v-bind:class="{
                    onService: sotsCounter === 1,
                    offService: sotsCounter === 0,
                  }"
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://levelup.ee/digitaalne-sihtotsing/?utm_source=tooturu-kalkulaator&utm_medium=landing-page&utm_campaign=kalkulaator"
                    v-bind:class="{
                      serviceP: sotsCounter === 1,
                      servicePoff: sotsCounter === 0,
                    }"
                    >Digitaalne sihtotsing</a
                  >
                </div>
              </v-col>
              <v-col class="serviceButton1">
                <div
                  v-bind:class="{
                    onService: karCounter === 1,
                    offService: karCounter === 0,
                  }"
                >
                  <a
                    target="_blank"
                    role="a"
                    rel="noopener noreferrer"
                    href="https://levelup.ee/kontakt/?utm_source=tooturu-kalkulaator&utm_medium=landing-page&utm_campaign=kalkulaator"
                    v-bind:class="{
                      serviceP: karCounter === 1,
                      servicePoff: karCounter === 0,
                    }"
                    >Karjäärileht</a
                  >
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-card
          v-if="windowWidth < 1024"
          v-bind:class="{
            graphCards2: loadingCounter === 0,
            graphCardsLoading: loadingCounter === 1,
          }"
        >
          <v-card class="graphCard">
            <p>Eesti töötuse määr</p>
            <div style="width: 90%; margin-left: 4%; height: 12vh">
              <canvas
                class="candidateChart"
                id="candidate-chartMobile1"
              ></canvas>
            </div>
          </v-card>
          <v-card class="graphCard" style="margin-top: 6.8%">
            <p>
              Töötaja leidmise raskusaste
              <br />(tööturu konkurents)
            </p>

            <div style="width: 90%; margin-left: 4%; height: 12vh">
              <canvas
                class="candidateChart"
                id="candidate-chartMobile2"
              ></canvas>
            </div>
          </v-card>
        </v-card>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
import axios from "axios";
import Chart from "chart.js";
Chart.defaults.global.legend.labels.usePointStyle = true;
Chart.defaults.global.legend.display = false;

export default {
  name: "login",
  data() {
    return {
      loadingCounter: 0,
      varbCounter: 0,
      sotsCounter: 0,
      karCounter: 0,
      fCounter: 0,
      gCounter: 0,
      mCounter: 0,
      iCounter: 0,
      lCounter: 0,
      yCounter: 0,
      vCounter: 0,
      graphLabels: ["1.2019", "2.2019", "3.2019", "4.2019", "5.2019", "6.2019"],
      graphValues: [0, 4, 3, 2, 3, 4],
      begin: null,
      andmed: null,
      graphData: null,
      graphDates: null,
      graphStats: null,
      graphStats2: null,
      valitudAndmed: null,
      valdKond: null,
      ametiNimetus: null,
      windowWidth: window.innerWidth,
      safari: false,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    this.isSafari();
  },
  watch: {
    valdKond: function () {
      let ametid = [];
      let chosenValdkond = this.valdKond.Valdkond;
      this.andmed.forEach((e) => {
        if (e.Valdkond === chosenValdkond) {
          ametid.push(e);
        }
      });
      this.valitudAndmed = ametid;
    },
  },
  methods: {
    isSafari() {
      let isSafari =
        /*eslint-disable */
        /constructor/i.test(window.HTMLElement) ||
        (function (p) {
          return p.toString() === "[object SafariRemoteNotification]";
        })(!window["safari"] || safari.pushNotification);
      /*eslint-enable */
      this.safari = isSafari;
    },
    getSocials() {
      if (this.begin.Kanalid.includes("Facebook")) {
        this.fCounter = 1;
      }
      if (this.begin.Kanalid.includes("Messenger")) {
        this.mCounter = 1;
      }
      if (this.begin.Kanalid.includes("Google")) {
        this.gCounter = 1;
      }
      if (this.begin.Kanalid.includes("Youtube")) {
        this.yCounter = 1;
      }
      if (this.begin.Kanalid.includes("LinkedIn")) {
        this.lCounter = 1;
      }
      if (this.begin.Kanalid.includes("Instagram")) {
        this.iCounter = 1;
      }
      if (this.begin.Kanalid.includes("VK")) {
        this.vCounter = 1;
      }
      if (this.begin.Teenus.includes("Sotsiaalmeedia")) {
        this.sotsCounter = 1;
      }

      if (this.begin.Teenus.includes("Värbamiskampaania")) {
        this.varbCounter = 1;
      }
      if (this.begin.Teenus.includes("Karjäärileht")) {
        this.karCounter = 1;
      }
    },
    calculate() {
      if (window.myChart) {
        window.myChart.destroy();
      }
      this.begin = this.ametiNimetus;
      this.fCounter = 0;
      this.gCounter = 0;
      this.iCounter = 0;
      this.lCounter = 0;
      this.yCounter = 0;
      this.mCounter = 0;
      this.varbCounter = 0;
      this.sotsCounter = 0;
      this.karCounter = 0;
      this.loadingCounter = 1;
      setTimeout(() => {
        this.loadingCounter = 0;
      }, 4000);
      this.getSocials();
      this.createChart1("candidate-chart", this.candidateChartData);
      this.createChart2("candidate-chart2", this.candidateChartData);
      if (this.windowWidth < 1024) {
        this.createChart1("candidate-chartMobile1", this.candidateChartData);
        this.createChart2("candidate-chartMobile2", this.candidateChartData);
      }
      if (this.windowWidth < 1024) {
        document.getElementById("calcBtn").scrollIntoView();
      }
    },
    async getData() {
      let data = null;
      let graphsData = null;
      await axios
        .get(
          "https://api.steinhq.com/v1/storages/5ec6e5dd83c30d0425e2c39d/Sihtgrupid"
        )
        .then((response) => {
          data = response.data;
        });
      await axios
        .get(
          "https://api.steinhq.com/v1/storages/5ec6e5dd83c30d0425e2c39d/Analüütika"
        )
        .then((response) => {
          graphsData = response.data;
        });
      graphsData.slice(Math.max(graphsData.length - 12, 0));
      this.graphData = graphsData.filter((_, i) => i % 3 == 0);
      data.sort((a, b) => a.Valdkond.localeCompare(b.Valdkond));
      this.andmed = data;
      this.ametiNimetus = data[0];
      this.begin = data[0];
      this.getSocials();
      let result = this.graphData.map((a) => a.kuupaev);
      let result2 = this.graphData.map((a) => a.töötusemäär);
      let result3 = this.graphData.map((a) => a.raskusaste);
      this.graphDates = result;
      this.graphStats = result2;
      this.graphStats2 = result3;
      setTimeout(() => {
        this.createChart1("candidate-chart", this.candidateChartData);
        this.createChart2("candidate-chart2", this.candidateChartData);
        if (this.windowWidth < 1024) {
          this.createChart1("candidate-chartMobile1", this.candidateChartData);
          this.createChart2("candidate-chartMobile2", this.candidateChartData);
        }
      }, 2500);
    },
    createChart1(chartId) {
      Chart.Legend.prototype.afterFit = function () {
        this.height = this.height + 30;
      };

      const ctx = document.getElementById(chartId);
      window.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.graphDates,
          datasets: [
            {
              data: this.graphStats,
              backgroundColor: "#fa98cb",
              borderColor: ["#fa98cb"],
              fill: true,
              borderWidth: 3,
              yAxisID: "y-axis-1",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          legend: {
            align: "start",
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
              },
            ],
            yAxes: [
              {
                id: "y-axis-1",
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                position: "right",
                display: true,
                offset: 2,
                labelString: "Percentage",
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 10,
                  stepSize: 5,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return (
                  data["labels"][tooltipItem["index"]] +
                  ": " +
                  data["datasets"][0]["data"][tooltipItem["index"]] +
                  "%"
                );
              },
            },
          },
        },
      });
    },
    createChart2(chartId) {
      Chart.Legend.prototype.afterFit = function () {
        this.height = this.height + 30;
      };

      const ctx = document.getElementById(chartId);
      window.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.graphDates,
          datasets: [
            {
              data: this.graphStats2,
              backgroundColor: "#6353fa",
              borderColor: ["#6353fa"],
              fill: true,
              borderWidth: 3,
              yAxisID: "y-axis-1",
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 1,
          legend: {
            align: "start",
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
              },
            ],
            yAxes: [
              {
                id: "y-axis-1",
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                },
                position: "right",
                display: true,
                offset: 2,
                labelString: "Percentage",
                ticks: {
                  beginAtZero: true,
                  stepSize: 20,
                },
              },
            ],
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                return (
                  data["labels"][tooltipItem["index"]] +
                  ": " +
                  data["datasets"][0]["data"][tooltipItem["index"]] +
                  "%"
                );
              },
            },
          },
        },
      });
    },
  },
  computed: {},
};
</script>
<style lang="scss">
* {
  font-family: "Nunito", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");
.v-application--is-ltr {
  .v-text-field--outlined fieldset {
    border-radius: 20px;
  }
}
.v-application {
  background: none transparent !important;
}
.chaport-container {
  .chaport-launcher {
    display: none !important;
  }
}
.container {
  padding: 0 !important;
}
.calculatorHeader {
  font-family: "Fredoka One", cursive;
  font-size: 1.6vw;
  font-weight: 400;
  margin-bottom: 5% !important;
  color: #6353fa;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2%;
}
.calculatorDescription {
  font-family: "Nunito", sans-serif;
  padding-left: 3vw;
  padding-right: 3vw;
  text-align: left;
  font-size: 1vw;
}
.v-application {
  .primary.lighten-3 {
    background-color: #e0e1e2 !important;
  }
}
.searchRow {
  padding-top: 9%;
  .v-text-field {
    max-width: 60%;
    max-height: 50%;
    .v-icon {
      color: #fa98cb;
    }
  }
}
.calculatorCard {
  width: 26%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  border-radius: 20px !important;
}
.calculatorIcon {
  padding-top: 8%;
}
.selectors {
  max-width: 60%;
  .v-icon {
    color: #fa98cb;
  }
}
.calculatorBtn {
  background-color: #6353fa !important;
  color: #ffffff !important;
  border-radius: 30px;
  width: 60%;
  justify-self: center;
  margin-bottom: 12%;
  height: 3rem !important;
  text-transform: none;
  .v-btn__content {
    font-size: 1vw;
    word-spacing: 1%;
    font-family: "Nunito", sans-serif;
  }
}
.statsCard {
  width: 50vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  border-radius: 20px !important;
  margin-left: 3%;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}
.difficultySlider {
  width: 75%;
  margin-left: 13%;
  max-height: 1vh;
  margin-top: 5%;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.displayNone {
  display: none;
}
.displayLoading {
  position: fixed;
  z-index: 999;
  max-height: 30vw;
  top: 40%;
  margin-left: 20%;
}
.profileCardLoading {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  margin-left: 4vw;
  border-radius: 23px !important;
  width: 37%;
  max-height: 30%;
  display: none;
}
.profileCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  margin-left: 4vw;
  border-radius: 23px !important;
  width: 37%;
  max-height: 30%;
}
.profileIcon {
  width: 20% !important;
  height: 20% !important;
}
.p1 {
  color: #717171;
  font-size: 0.9vw;
  margin-left: 11%;
  margin-top: 7%;
  margin-bottom: 0 !important;
}
.p2 {
  margin-left: 11%;
  font-size: 1.3vw;
  color: #54595f;
  // font-weight: 400;
  font-family: "Fredoka One", cursive;
}
.p3 {
  margin-bottom: 0 !important;
  margin-top: 4%;
  margin-left: 11%;
  font-family: "Nunito", sans-serif;
}
.p4 {
  font-size: 1.7vw;
  color: #6353fa;
  margin-left: 11%;
  font-family: "Fredoka One", cursive;
}
.p5 {
  margin-bottom: 0 !important;
  margin-top: 0%;
  margin-left: 11%;
  font-family: "Nunito", sans-serif;
}
.p6 {
  font-size: 1.3vw;
  color: #6353fa;
  margin-left: 11%;
  font-family: "Fredoka One", cursive;
}
.p7 {
  margin-bottom: 0 !important;
  margin-top: 3%;
  margin-left: 11%;
  font-family: "Nunito", sans-serif;
}
.p8 {
  font-size: 1.3vw;
  color: #6353fa;
  margin-left: 11%;
  font-family: "Fredoka One", cursive;
  padding-bottom: 3%;
}
.profileRow1 {
  margin-left: 11%;
}
.profileRow2 {
  margin-left: 40%;
}
.profileRow2Safari {
  margin-left: 22%;
}
.progressCircle {
  margin-left: 10%;
  margin-top: 2%;
}
.graphCards {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  margin-left: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 23px !important;
  width: 45%;
  height: 22%;
  padding-left: 1%;
  padding-right: 1%;
}
.graphCardsLoading {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  margin-left: 4%;
  padding-top: 2%;
  padding-bottom: 2%;
  border-radius: 23px !important;
  width: 45%;
  height: 22%;
  padding-left: 1%;
  padding-right: 1%;
  visibility: hidden;
}
.graphCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  margin-left: 3%;
  padding-bottom: 4%;
  border-radius: 23px !important;
  width: 95%;
  height: 25%;
  p {
    text-align: left;
    padding-left: 7%;
    padding-top: 5%;
    font-size: 1vw;
    margin-bottom: 0;
  }
}
.channelRow {
  margin-top: 2%;
  margin-left: 1%;
}
.channelRowLoading {
  margin-top: 5%;
  margin-left: 1%;
  display: none;
}
.serviceRow {
  margin-left: 7%;
  height: 20%;
}
.serviceRowLoading {
  margin-left: 7%;
  padding-bottom: 5%;
  visibility: hidden;
}
g {
  fill: black !important;
}
.v-slider__track-container {
  height: 5px !important;
}
.v-slider__thumb {
  height: 16px !important;
  width: 16px !important;
  box-shadow: 0 4px 8px 0 rgba(208, 208, 208, 0.7),
    0 6px 20px 0 rgba(0, 0, 0, 0.7);
}
.onLogo {
  width: 3vw;
  padding-right: 10px;
  padding-left: 11px;
}
.offLogo {
  display: none;
}
.onService {
  width: 100%;
  height: 140%;
  border-radius: 28px;
  background: #73f97f;
  padding-top: 1rem;
}
.offService {
  width: 100%;
  height: 140%;
  border-radius: 28px;
  background: none;
  border: 1px solid #54585f;
  padding-top: 1rem;
}
.serviceP {
  display: block;
  font-family: "Fredoka One", cursive;
  font-size: 0.9vw;
  color: #54585f !important;
  // padding-top: 5% !important;
}
.servicePoff {
  display: block;
  font-family: "Fredoka One", cursive;
  font-size: 0.9vw;
  color: #54595f !important;
  // padding-top  : 5% !important;
}
.mehedParagraph {
  margin-left: 20%;
}
.mehedParagraphSafari {
  margin-left: 16%;
}
.mainRow {
  width: 85vw;
  margin-left: 8%;
  margin-top: 0.2%;
  background: none transparent;
}
.statsRow {
  margin-top: 5%;
}
.channelParagraph {
  font-size: 1vw;
}
.difficultyParagraph {
  font-size: 1vw;
  margin-bottom: 0 !important;
}
.sliderNumbers {
  font-size: 1vw;
  margin-top: 4% !important;
  text-align: end;
  padding-right: 15%;
}
.serviceParagraph {
  padding-top: 2%;
  font-size: 1vw;
}
.serviceLine {
  max-height: 15%;
  margin-left: 2%;
  margin-right: 2%;
  justify-content: center;
}
.serviceButton {
  height: 4rem;
  max-width: 32% !important;
}
.serviceButton1 {
  max-width: 32% !important;
  height: 4rem;
}
.serviceCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.025),
    0 6px 20px 0 rgba(0, 0, 0, 0.025);
  border-radius: 20px !important;
  width: 92%;
  max-height: 90%;
}
@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .progressCircle {
    height: 40px !important;
    width: 40px !important;
  }
  .v-progress-circular__info {
    font-size: 10px;
  }
  .profileRow2 {
    margin-left: 37%;
  }
  .mehedParagraph {
    margin-left: 9%;
  }
}
@media only screen and (max-width: 1023px) {
  .mainRow {
    width: 85vw;
    margin-left: 8%;
    margin-top: 0.2%;
    background: none transparent;
    display: grid;
  }
  .calculatorCard {
    width: 100%;
  }
  .calculatorHeader {
    font-size: 2rem;
  }
  .calculatorDescription {
    font-size: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .calculatorBtn {
    .v-btn__content {
      font-size: 1rem;
    }
  }
  .statsCard {
    display: grid;
    margin-top: 5%;
    margin-left: 0;
    width: 100%;
  }
  .statsRow {
    display: block;
    margin-top: 0;
  }
  .profileCard {
    margin-left: 4%;
    width: 92%;
    margin-top: 1%;
  }
  .p1 {
    font-size: 1rem;
    margin-top: 0;
    padding-top: 4%;
  }
  .p2 {
    font-size: 1.5rem;
  }
  .p3 {
    font-size: 1.3rem;
  }
  .p4 {
    font-size: 1.3rem;
  }
  .p5 {
    font-size: 1.2rem;
  }
  .p6 {
    font-size: 1.25rem;
  }
  .p7 {
    font-size: 1.2rem;
  }
  .p8 {
    font-size: 1.25rem;
  }
  .graphCards {
    width: 92%;
    display: none;
    border-radius: 23px !important;
  }
  .graphCard {
    p {
      font-size: 1rem;
    }
  }
  .channelRow {
    display: block;
    span {
      padding-right: 4%;
    }
  }
  .channelParagraph {
    font-size: 1.3rem;
    padding-right: 4%;
  }
  .onLogo {
    width: 2rem;
    padding-right: 5px;
    padding-left: 5px;
  }
  .difficultyParagraph {
    font-size: 1.3rem;
    padding-right: 5.5%;
  }
  .sliderNumbers {
    font-size: 1.2rem;
    margin-top: 4% !important;
    text-align: end;
    padding-right: 15%;
  }
  .serviceParagraph {
    font-size: 1.3rem;
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .onService {
    width: 100%;
    padding-bottom: 5%;
    padding-top: 1rem;
    height: 3.5rem;
  }
  .offService {
    padding-bottom: 5%;
    padding-top: 1rem;
    height: auto;
    height: 3.5rem;
  }
  .serviceP {
    font-size: 1rem;
  }
  .servicePoff {
    font-size: 1rem;
  }
  .serviceButton {
    display: block;
    max-width: 100% !important;
  }
  .serviceButton1 {
    display: block;
    max-width: 100% !important;
  }
  .serviceLine {
    display: block;
    margin-left: 9%;
    padding-right: 4%;
  }
  .serviceCard {
    width: 93%;
    margin-left: 2%;
    margin-right: 2%;
    padding-bottom: 10%;
  }
  .serviceRow {
    margin-left: 0;
    padding-bottom: 1%;
    height: auto;
  }
  .graphCards2 {
    width: 100%;
    border-radius: 23px !important;
    padding-bottom: 1%;
  }
  .graphCard {
    margin-left: 2%;
    margin-right: 2%;
    width: 96%;
  }
  .displayLoadingMobile {
    position: relative;
    margin-top: 50%;
    width: 30%;
  }
  .displayLoading {
    display: none;
  }
  .difficultySlider {
    margin-left: 9%;
  }
}
</style>